<template>
  <div
    :class="[
      (featured || sponsored || theme) && !true
        ? 'mb-6 md:mb-16'
        : null,
      (featured || sponsored || theme) && !true && index !== 0
        ? 'mt-5 md:mt-12'
        : null,
      { 'swiper-slide': slider },
    ]"
  >
    <Transition name="skeleton" mode="out-in">
      <div v-if="loaded" key="content">
        <div
          v-if="featured || theme"
          class="px-3 pt-4"
          :class="[
            true ? 'mb-3 pb-3' : 'pb-6 -mb-3 md:-m-3 rounded-t-xl',
            'bg-black',
          ]"
        >
          <div class="flex flex-row items-center">
            <div
              class="text-white mb-1"
              :class="true ? 'list-title-xxxs' : 'label-m font-bold'"
            >
              {{ featured?.title ?? theme?.title }}
            </div>
            <span
              v-if="theme && theme.themeid"
              class="uppercase text-white text-opacity-50 ml-auto label-s transition-opacity duration-100 hover:opacity-100"
            >
              <NuxtLink
                :to="{
                  name: indexStore.currentPaperSlug
                    ? 'paper_subject'
                    : 'subject',
                  params: {
                    subject: theme.themeid,
                    paper: indexStore.currentPaperSlug,
                  },
                }"
              >
                {{ $t('ShowAll') }}
              </NuxtLink>
            </span>
          </div>
          <div
            v-if="featured?.description || theme?.description"
            class="body-s mb-2 w-full text-white"
            v-html="featured?.description ?? theme?.description"
          />
        </div>
        <div
          v-if="sponsored"
          class="bg-skyblue md:-mx-3 md:-my-4 px-3 py-4 rounded-xl border mb-4"
        >
          <div class="flex relative flex-col z-10">
            <div class="text-black flex items-start label-m">
              <NuxtLink
                :to="{ name: 'sponsored_id', params: { id: 'vejledning' } }"
              >
                <img
                  class="w-5 h-5 mr-2 inline-block"
                  src="@/assets/icons/info.svg?url"
                  alt="Info"
                />
              </NuxtLink>
              <NuxtLink :to="article.TargetUrl">
                {{ $t('PayedArticle') }}
              </NuxtLink>
              <NuxtLink :to="article.TargetUrl" class="ml-auto">
                <img
                  :src="article.LogoUrl"
                  :alt="$t('PayedArticle')"
                  class="w-20"
                />
              </NuxtLink>
            </div>
            <h2 class="list-title-m my-3">
              <NuxtLink :to="article.TargetUrl">
                {{ article.Title }}
              </NuxtLink>
            </h2>
            <h3 class="list-title-s !font-normal">
              <NuxtLink :to="article.TargetUrl">
                {{ article.Name }}
              </NuxtLink>
            </h3>
          </div>
        </div>
        <div
          v-else
          class="block"
          :class="[
            ((featured || theme) && !true) || inText
              ? 'bg-white md:-mx-3 md:-my-4 px-3 py-4 rounded-xl border mb-4'
              : null,
          ]"
        >
          <NuxtLink
            v-if="bullet"
            :to="articleRouteObject"
            class="flex relative justify-between z-10"
            :class="(index === 0 && !sameSize) || slider ? 'flex-col' : ''"
          >
            <h2
              class="list-title-xxxs"
              v-html="
                '• ' +
                (article.Headline || article.HeadLine || article.headline)
              "
            />
          </NuxtLink>
          <div
            v-else
            :class="[
              {
                'text-center': /history/.test(type) && index === 0 && !small,
              },
            ]"
          >
            <NuxtLink
              :to="articleRouteObject"
              class="flex relative justify-between z-10"
              :class="
                (index === 0 && !sameSize) || slider || alwaysImage
                  ? 'flex-col'
                  : ''
              "
            >
              <NuxtImg
                v-if="/podcast/.test(type) && (index !== 0 || sameSize)"
                class="shrink-0 overflow-hidden"
                :width="96"
                :height="96"
                :src="
                  podcast
                    ? config.public.site.legacydomain + podcast.image
                    : article.ImageUrl || article.imageUrl
                "
                :class="[
                  !true && !small
                    ? 'rounded-md'
                    : !true && small
                    ? 'rounded-md'
                    : '',
                  small
                    ? 'h-18 w-18 mr-3'
                    : 'h-18 md:h-24 w-18 md:w-24 mr-3 md:mr-5',
                ]"
                :loading="loadingString"
                :preload="preload"
              />
              <figure
                v-if="
                  ((article.ImageUrl || article.imageurl || article.imageUrl) &&
                    index === 0 &&
                    !sameSize) ||
                  slider ||
                  alwaysImage
                "
                class="relative"
                :class="{
                  'order-2': /history/.test(type) && index === 0 && !small,
                }"
              >
                <NuxtImg
                  :src="
                    article.ImageUrl || article.imageurl || article.imageUrl
                  "
                  class="w-full relative"
                  :alt="
                    article.Headline ||
                    article.HeadLine ||
                    article.headline ||
                    article.Title
                  "
                  :width="720"
                  :height="404"
                  :class="[
                    !true && !small
                      ? 'rounded-xl'
                      : !true && small
                      ? 'rounded-lg'
                      : null,
                    small || slider ? 'mb-3' : 'mb-3 md:mb-5',
                  ]"
                  :loading="loadingString"
                  :preload="preload"
                />
                <div
                  v-if="
                    /video/.test(type) ||
                    article.ToppictHTML ||
                    article.VideoProvider
                  "
                  class="rounded-full cursor-pointer bg-red h-15 w-15 flex items-center justify-center absolute top-1/2 left-1/2 z-10 transform -translate-x-1/2 -translate-y-1/2"
                >
                  <img
                    class="w-6 h-6 transform ml-3px inline-block"
                    src="@/assets/icons/play.svg?url"
                    alt="Play"
                  />
                </div>
              </figure>
              <div
                class="teaser-content"
                :class="[
                  index === 0 && !sameSize ? 'w-full md:w-9/10' : 'w-full mr-3',
                  {
                    'order-1 mb-6':
                      /history/.test(type) && index === 0 && !small,
                  },
                ]"
              >
                <div
                  v-if="
                    true &&
                    /comment/.test(type) &&
                    !slider &&
                    article.Quote &&
                    article.Quote[0]
                  "
                  class="list-title-m !font-light text-blue mb-2"
                >
                  “{{ article.Quote[0].Quote }}”
                </div>
                <h2
                  :class="getTitleClassList(index, type)"
                  v-html="
                    article.Headline ||
                    article.HeadLine ||
                    article.headline ||
                    article.Title
                  "
                />
                <div
                  v-if="showTeaserText"
                  class="body-s mt-1 md:mt-3 line-clamp-3"
                  :class="index === 1 ? 'hide-on-mobile' : ''"
                  v-html="article.MainTeaser"
                />
                <div
                  v-if="!(/history/.test(type) && index === 0 && !small)"
                  class="mt-2 md:mt-3 list-label flex flex-wrap items-center gap-y-1"
                >
                  <span
                    :class="/review/.test(type) ? 'hidden md:inline-block' : ''"
                  >
                    <span
                      v-if="
                        indexStore.currentPaper?.RecordId === 1 && primaryPaper
                      "
                      class="mr-2 flex items-center font-bold"
                      :class="{ 'text-blue': !true }"
                      :style="{
                        color: true
                          ? primaryPaper.ColorRGB
                          : undefined,
                      }"
                      ><img
                        v-if="!true"
                        src="/A_Logomark.svg"
                        alt="Altinget logo"
                        class="w-4 h-4 mr-1"
                      />{{ primaryPaper.Name }}</span
                    >
                  </span>
                  <span
                    v-if="
                      true &&
                      article.TypeName &&
                      article.TypeName !== 'Artikel'
                    "
                    class="mr-2 opacity-50"
                    >{{ article.TypeName }}</span
                  >
                  <span
                    v-if="showStandardLabel[type] !== undefined"
                    class="mr-2 opacity-50"
                    >{{ showStandardLabel[type] }}</span
                  >
                  <span v-if="type === 'review'" class="mr-2">
                    <ReviewIcon
                      v-for="item in 6"
                      :key="item"
                      class="w-4 h-4 mr-1px inline-block fill-current"
                      :class="
                        (article.rating && item <= article.rating) ||
                        (article.Rating && item <= article.Rating)
                          ? 'text-red'
                          : 'text-black opacity-10'
                      "
                    />
                  </span>

                  <template
                    v-if="
                      /opinion|article|review|debate|analysis|comment|politicalSpeech|kronik|kultur|newjb|techtendenser|chiefblog|newleadership|kultur|names|column/.test(
                        type
                      )
                    "
                  >
                    <ArticleWriterLabel
                      v-if="
                        article.CustomWriter ||
                        (article.Writers && article.Writers.length > 0)
                      "
                      :article="article"
                      :type="type"
                      :lazy="props.lazy"
                      :preload="props.preload"
                    />
                    <span
                      v-else-if="article.FeedName"
                      class="font-bold opacity-50 line-clamp-1 mr-2"
                      >{{ article.FeedName }}</span
                    >
                  </template>
                  <span
                    v-if="/podcast/.test(type) && podcast"
                    class="opacity-50 mr-2"
                    >{{ podcast.name }}
                    &#183;
                    <b>{{ podcast.duration }}</b></span
                  >
                  <span v-if="/video/.test(type)" class="opacity-50 mr-2"
                    >Video
                    {{ videoLength }}
                  </span>

                  <div
                    v-if="article.TextToSpeechFile && true"
                    class="mr-1"
                  >
                    <ArticleReader
                      v-if="userStore.hasSubscription(1)"
                      small
                      :file="article?.TextToSpeechFile"
                      :headline="
                        article.Headline ||
                        article.HeadLine ||
                        article.headline ||
                        article.Title
                      "
                      :duration="article.TextToSpeechDuration"
                      :image="article.ImageUrl || article.imageUrl"
                      class="mr-2"
                    />
                    <IconMute
                      v-else
                      id="icon-mute"
                      class="fill-blue"
                      viewBox="0 -4 30 30"
                    />
                  </div>

                  <ArticleSaver
                    v-if="
                      userStore.hasLoginType('newuser', 'SSO') &&
                      !rss &&
                      !(/history/.test(type) && index === 0 && !small)
                    "
                    :article-id="getArticleId(article)"
                    :small="small"
                    :index="index"
                  />
                </div>
              </div>
              <template v-if="index > 0 || sameSize">
                <button
                  v-if="/podcast/.test(type)"
                  class="rounded-full cursor-pointer bg-red flex items-center justify-center shrink-0 self-center"
                  :class="small ? 'h-8 w-8' : 'h-8 md:h-10 w-8 md:w-10'"
                  @click.prevent="playAudio"
                >
                  <img
                    class="inline-block"
                    src="@/assets/icons/play.svg?url"
                    alt="Play"
                    :class="
                      small
                        ? 'h-4 w-4 ml-2px'
                        : 'h-4 md:h-5 w-4 md:w-5 ml-2px md:ml-3px'
                    "
                  />
                </button>
                <CommonImageLoader
                  v-if="
                    ((type !== 'podcast' && !slider && !alwaysImage) ||
                      alwaysImageSmall) &&
                    (article.ImageUrl ||
                      article.imageurl ||
                      article.imageUrl) &&
                    !small
                  "
                  class="self-start hidden md:flex w-21 h-12 grow-0 shrink-0 overflow-hidden items-center justify-center"
                  :src="
                    article.ImageUrl || article.imageurl || article.imageUrl
                  "
                  :alttext="
                    article.Headline || article.HeadLine || article.headline
                  "
                  :width="300"
                  :height="170"
                  :class="[
                    !true && !small
                      ? 'rounded-md'
                      : !true && small
                      ? 'rounded-sm'
                      : '',
                    small ? 'md:w-21 md:h-12' : 'md:w-45 md:h-25',
                  ]"
                  :lazy="lazy"
                  :preload="preload"
                >
                  <div
                    v-if="
                      /video/.test(type) ||
                      article.ToppictHTML ||
                      article.VideoProvider
                    "
                    class="rounded-full cursor-pointer bg-red h-10 w-10 flex items-center justify-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  >
                    <img
                      class="w-5 h-5 transform ml-2px inline-block"
                      src="@/assets/icons/play.svg?url"
                      alt="Play"
                    />
                  </div>
                </CommonImageLoader>
              </template>
            </NuxtLink>
            <template v-if="theme && theme.articles">
              <template v-for="(themeArticle, themeIndex) in theme.articles">
                <template v-if="themeIndex !== 0">
                  <CommonDivider
                    :key="'divider' + themeIndex"
                    class="-mx-3 px-3"
                  />
                  <NuxtLink
                    :key="'theme' + themeIndex"
                    :to="`${currentPage}/${$t('Routes.Article')}/${
                      themeArticle.UrlKey ?? themeArticle.urlKey
                    }`"
                  >
                    <h2
                      class="list-title-xs"
                      v-html="
                        themeArticle.Headline ||
                        themeArticle.HeadLine ||
                        themeArticle.headline
                      "
                    />
                    <div class="mt-2 md:mt-3 list-label flex items-center">
                      <ArticleSaver
                        :article-id="getArticleId(themeArticle)"
                        :index="themeIndex"
                        :small="small"
                      />
                    </div>
                  </NuxtLink>
                </template>
              </template>
            </template>
          </div>
        </div>
      </div>
      <ContentLoader
        v-else
        key="loader"
        :height="index === 0 && !small && !sameSize ? 320 : 60"
        :primary-color="indexStore.skeleton.primaryColor"
        :secondary-color="indexStore.skeleton.secondaryColor"
      >
        <template v-if="index === 0 && !small && !sameSize">
          <rect x="0" y="0" rx="3" ry="3" width="400" height="235" />
          <rect x="0" y="250" rx="3" ry="3" width="410" height="16" />
          <rect x="0" y="270" rx="3" ry="3" width="380" height="16" />
          <rect
            class="hidden md:block"
            x="0"
            y="295"
            rx="3"
            ry="3"
            width="380"
            height="6"
          />
          <rect
            class="hidden md:block"
            x="0"
            y="305"
            rx="3"
            ry="3"
            width="200"
            height="6"
          />
        </template>
        <template v-else>
          <rect x="0" y="0" rx="3" ry="3" width="280" height="12" />
          <rect x="0" y="16" rx="3" ry="3" width="260" height="12" />
          <rect
            class="hidden md:visible"
            x="0"
            y="36"
            rx="3"
            ry="3"
            width="280"
            height="6"
          />
          <rect
            class="hidden md:visible"
            x="0"
            y="46"
            rx="3"
            ry="3"
            width="200"
            height="6"
          />
          <rect x="300" y="0" rx="3" ry="3" width="100" height="60" />
        </template>
      </ContentLoader>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import ReviewIcon from '@/assets/icons/rating.svg?component'
import IconMute from '~/assets/icons/mute.svg?component'
import { ContentLoader } from 'vue-content-loader'
import type { ContentArticle } from '~/typesManual/content_api/article'

const nuxtApp = useNuxtApp()
const config = useRuntimeConfig()

const audioPlayer = useAudioPlayer()
const dataLayer = useDataLayer()

const userStore = useUserStore()
const indexStore = useIndexStore()

type Theme = {
  title: string
  description: string
  themeurl: string
  themeid: string
  articles: ContentArticle[]
}

type Featured = {
  title: string
  description: string
}

const props = withDefaults(
  defineProps<{
    article: ContentArticle
    index: number
    paper?: number
    featured?: Featured
    theme?: Theme
    sponsored?: unknown
    inText?: boolean
    small?: boolean
    alwaysImage?: boolean
    alwaysImageSmall?: boolean
    alwaysTeaser?: boolean
    sameSize?: boolean
    slider?: boolean
    bullet?: boolean
    rss?: boolean
    lazy?: boolean
    preload?: boolean
    grey?: boolean
    partOfTheme?: boolean
  }>(),
  {
    paper: 0,
    inText: false,
    small: false,
    alwaysImage: false,
    alwaysImageSmall: false,
    alwaysTeaser: false,
    sameSize: false,
    slider: false,
    bullet: false,
    rss: false,
    lazy: true,
    preload: false,
  }
)
const showStandardLabel: { [key: string]: string } = {
  debate: nuxtApp.$t('Debate'),
  analysis: nuxtApp.$t('Analysis'),
  opinion: nuxtApp.$t('Comment'),
  politicalSpeech: nuxtApp.$t('Speech'),
  column: nuxtApp.$t('Spaltist'),
  names: nuxtApp.$t('NamesNews'),
  kronik: nuxtApp.$t('Chronic'),
}

const loadingString = computed(() => (props.lazy ? 'lazy' : 'eager'))

const podcast = props.article.Podcast || props.article.podcast

const loaded = computed(() => typeof props.article === 'object')

const getArticleId = (article: ContentArticle) => {
  if (article.RecordId) {
    if (typeof article.RecordId === 'string') {
      return parseInt(article.RecordId)
    }

    return article.RecordId
  } else if (article.recordId) {
    return article.recordId
  } else if (article.articleId) {
    return article.articleId
  }

  return article.id ?? 0
}

const getTitleClassList = (index: number, type: string) => {
  const classList = []

  if (index === 0 && !props.small && !props.sameSize) {
    classList.push('list-title-l')
  } else if (index === 0 && props.small && !props.sameSize) {
    classList.push('list-title-xs')
  } else if (index === 1 && !props.small && !props.sameSize) {
    classList.push('list-title-m')
  } else if (props.slider) {
    classList.push('list-title-xxs')
  } else if (true && props.small) {
    classList.push('list-title-xs')
  } else if (!true && props.small) {
    classList.push('list-title-xxxs')
  } else if (true && /opinion/.test(type)) {
    classList.push('list-title-m')
  } else {
    classList.push('list-title-s')
  }

  if (props.grey) {
    classList.push('opacity-50')
  }

  return classList
}

const currentPaper = computed(() => {
  if (props.paper) {
    if (props.paper === 1) {
      return ''
    }
    const targetPaper = indexStore.papers.find(
      (x) => x?.RecordId === props.paper
    )
    return targetPaper?.RecordId === 1 ? '' : `/${targetPaper?.Url}`
  } else if (
    indexStore.currentPaper &&
    indexStore.currentPaper.RecordId !== 1
  ) {
    return indexStore.currentPaper.RecordId === 1
      ? ''
      : `/${indexStore.currentPaper.Url}`
  } else {
    return ''
  }
})

const currentPage = computed(() => {
  if (
    props.article &&
    props.article.PrimaryPaper &&
    props.article.PrimaryPaper !== '1'
  ) {
    const targetPaper = indexStore.papers.find(
      (x) => x.RecordId === parseInt(props.article.PrimaryPaper ?? '-1')
    )
    return `/${targetPaper?.Url}`
  } else {
    return currentPaper.value
  }
})

const type = computed(() => {
  const articleType = props.article.Type ?? props.article.type
  if (articleType != undefined && !props.rss) {
    return getArticleType(articleType)
  } else {
    return 'article'
  }
})

const playAudio = () => {
  const podcast = props.article.podcast ?? props.article.Podcast
  if (!podcast) return
  // Track plays
  dataLayer.podcast.play(podcast.recordid ?? '', props.article.Headline ?? '')

  audioPlayer.play({
    url: `${config.public.site.legacydomain}${podcast.file}`,
    imageUrl: `${config.public.site.legacydomain}${podcast.image}`,
    title: props.article.HeadLine,
  })
}

const primaryPaper = computed(() => {
  if (
    typeof props.article === 'object' &&
    props.article.Papers &&
    typeof props.article.Papers === 'object'
  ) {
    const paper = props.article.Papers.find((paper) => paper.Primary === 1)
    if (paper) {
      return indexStore.papers.find((x) => x.RecordId === paper.Paper)
    }
    return props.article.Papers.find((paper) => paper.Primary === 1)
  } else {
    return null
  }
})

const articleRouteObject = computed(() => {
  if (props.rss) {
    return `/rss/${props.article.RecordId}`
  } else {
    return {
      name: indexStore.currentPaperSlug ? 'paper_article' : 'article',
      params: indexStore.currentPaperSlug
        ? {
            paper: indexStore.currentPaperSlug,
            id: props.article.UrlKey ?? props.article.urlKey,
          }
        : { id: props.article.UrlKey ?? props.article.urlKey },
    }
  }
})

const videoLength = computed(() => {
  if (!props.article.VideoLength) {
    return ''
  }

  let formattedLength = props.article.VideoLength

  // Remove leading 0 if video length starts by 0
  if (formattedLength.startsWith('0')) {
    formattedLength = props.article.VideoLength.substring(1)
  }

  return `&#183; ${formattedLength}`
})

const showTeaserText = computed(() => {
  if (true && !props.slider) {
    const isFirstOrAlwaysTeaser = props.index === 0 || props.alwaysTeaser
    return isFirstOrAlwaysTeaser
  }
  const isFirstOrSecondOrAlwaysTeaser =
    props.index === 0 || props.index === 1 || props.alwaysTeaser
  return isFirstOrSecondOrAlwaysTeaser && !props.small && !props.sameSize
})
</script>
